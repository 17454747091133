import { theme as defaultTheme, ThemeConfig } from "../../theme"

// https://www.figma.com/file/W1EleZpSRNA5Iv9fsOjKst/%F0%9F%93%90-Specs-%7C-Unicredit?type=design&node-id=32-9576&mode=design&t=3Z9InVzi8P64Y2Aq-4
const unicreditColors = {
  t1: "#006375",
  t2: "#007A91",
  t3: "#BFEBF3",
  t4: "#E7F8FB",
  k1: "#F5F5F5",
  k2: "#E5E5E5",
  k7: "#666666",
  k10: "#262626",
  W: "#FFFFFF",
}

const tenantColors = {
  ...defaultTheme.colors,
  brandedButtonBackground: unicreditColors.t2,
  brandedTextLink: unicreditColors.t2,
  brandedInputFocus: unicreditColors.t2,
  brandedButtonBorder: unicreditColors.t2,
  brandedRadioOptionBorder: unicreditColors.t2,
  brandedRadioOptionBackground: unicreditColors.t4,
  brandedCheckboxSelected: unicreditColors.t2,
  brandedOptionBackgroundSelected: unicreditColors.t4,
  brandedOptionBorderSelected: unicreditColors.t2,
  brandedLoaderColor1: unicreditColors.t3,
  brandedLoaderColor2: unicreditColors.t2,
  brandedSubmissionBannerBackground: unicreditColors.k1,
  brandedFileBoxBackground: unicreditColors.k1,
  brandedSubmissionBannerCircleBackground: unicreditColors.W,
  brandedTextInputFocus: unicreditColors.t2,
  brandedIconColor: unicreditColors.t2,
  brandedIconColorTenantOnly: unicreditColors.t2, // this is used for icons that need to be of the color of the brand for tenants but black for casavo
  brandedIconBackground: unicreditColors.t4,
  brandedChevron: unicreditColors.t2,
  brandedChipSoldBackground: unicreditColors.t4,
  brandedChipBackground: unicreditColors.k2,
  brandedCardHeaderBackground: unicreditColors.k1,
  brandedAvatarBorder: unicreditColors.k10,

  //Badge
  brandedBadgeBackground: unicreditColors.t4,
  brandedBadgeBorderColor: unicreditColors.t2,

  // LightBadge
  brandedLightBadgeBackground: unicreditColors.W,
  brandedLightBadgeBorderColor: unicreditColors.t2,
  brandedLightBadgeTextColor: unicreditColors.t1,

  brandedCardBackground: unicreditColors.t4,
  brandedCardBorderSelected: unicreditColors.t2,
  brandedComparableCardDotActive: unicreditColors.t2,
  brandedComparablePriceWrapperBackground: unicreditColors.t4,

  brandedPotentialBuyerAvatarBorder: unicreditColors.k10,
  brandedInterestedBuyerAvatarBorder: unicreditColors.t2,
  brandedInterestedBuyersBackground: unicreditColors.t4,
  brandedInterestedBuyerAdditionalBackground: unicreditColors.t2,
  brandedPotentialBuyerCardBorder: unicreditColors.t2,
  brandedShareWithBuyerCardBackground: unicreditColors.t4,
  brandedDataGatheringCompletedCardBackground: unicreditColors.t4,
  brandedAlertInfoBackground: unicreditColors.t4,
  brandedSaleProposalCardBackground: unicreditColors.t4,
}

export const ucscTheme: ThemeConfig = {
  ...defaultTheme,
  colors: tenantColors,
  borderRadius: {
    base: "8px",
    badge: "4px",
  },
  font: {
    title: {
      fontFamily: "Inter",
    },
  },
}
