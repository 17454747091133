export const isDevOrStaging = () =>
  process.env.NODE_ENV === "development" || process.env.NEXT_PUBLIC_APP_ENV === "staging"

export const isProduction = () => process.env.NEXT_PUBLIC_APP_ENV === "production"

export const isRunningE2ETests = () =>
  // @ts-ignore
  window?.isPlaywrightRunning === true

export const isStorybook = () =>
  // @ts-ignore
  !!process.env.STORYBOOK
