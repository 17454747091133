/* eslint-disable deprecation/deprecation -- it gets triggered by the definitions of the deprecated fields*/
import { colors } from "@app/base-ui/colors"

export const rawMq = {
  desktop: "min-width: 1024px",
  desktopHeader: "min-width: 1025px",
  large: "min-width: 768px",
  largeHeader: "min-width: 769px",
  medium: "min-width: 568px",
  small: "min-width: 360px",
  tablet: "min-width: 768px",
  xdesktop: "min-width: 1200px",
  xlarge: "min-width: 992px",
  xxlarge: "min-width: 1440px",
}

export const mq = {
  desktop: `@media(${rawMq.desktop})`,
  desktopHeader: `@media(${rawMq.desktopHeader})`,
  large: `@media(${rawMq.large})`,
  largeHeader: `@media(${rawMq.largeHeader})`,
  medium: `@media(${rawMq.medium})`,
  small: `@media(${rawMq.small})`,
  tablet: `@media(${rawMq.tablet})`,
  xdesktop: `@media(${rawMq.xdesktop})`,
  xlarge: `@media(${rawMq.xlarge})`,
  xxlarge: `@media(${rawMq.xxlarge})`,
}

export const flowColors = {
  /** @deprecated */
  black50: "rgba(29,29,27,0.5)",
  /** @deprecated */
  blue20: "rgba(31,130,192,0.2)",
  /** @deprecated */
  lightGrey: "#dce3ea",
  /** @deprecated */
  navy10: "rgba(51,68,84,0.1)",
  /** @deprecated */
  navy20: "rgba(51,68,84,0.2)",
  /** @deprecated */
  navy30: "rgba(51,68,84,0.3)",
  /** @deprecated */
  navy50: "rgba(51,68,84,0.5)",
  /** @deprecated */
  navy80: "rgba(51,68,84,0.8)",
}

const borderRadius = {
  base: "8px",
  badge: "100px",
}
const font = {
  title: {
    fontFamily: "Bagoss",
  },
}

export const theme = {
  colors: {
    /** @deprecated */
    black: "#1d1d1b",
    /** @deprecated */
    blue: "#1f82c0",
    /**
     *  @deprecated
     */
    charcoal: "#575755",
    /** @deprecated */
    cyan: "#66c3df",
    /** @deprecated */
    "disabled-background": "#f2f2f2",
    /** @deprecated */
    "disabled-border": "#e6e6e6",
    /** @deprecated */
    "disabled-color": "#999",
    /** @deprecated */
    green: "#67c057",
    /** @deprecated */
    grey: "#a9a9a9",
    /** @deprecated */
    greyLight: "#ececec",
    /** @deprecated */
    greyLighter: "#d8d8d8",
    /** @deprecated */
    lightPurple: "#f7f6fa",
    /** @deprecated */
    lightRed: "#fdefed",
    /** @deprecated */
    lightYellow: "#fef9ef",
    /** @deprecated */
    magenta: "#e20079",
    /** @deprecated */
    navy: "#334454",
    /** @deprecated */
    orange: "#f2994a",
    /** @deprecated */
    plum: "#ad448e",
    /** @deprecated */
    purple: "#7f72ad",
    /** @deprecated */
    red: "#e56456",
    /** @deprecated */
    "select-option-hover-background": "#deebff",
    /** @deprecated */
    "select-placeholder": "#a0aec0",
    /** @deprecated */
    snow: "#f5f5f6",
    /** @deprecated */
    white: "#ffffff",
    /** @deprecated */
    yellow: "#f0b549",
    ...flowColors,

    ...colors,
  },
  fontSizes: {
    "2xl": 30,
    "3xl": 42,
    "4xl": 48,
    default: 16,
    lg: 18,
    md: 16,
    sm: 14,
    xl: 22,
    xs: 12,
  },
  mqs: {
    desktop: `@media(${rawMq.desktopHeader})`,
    desktopHD: `@media(${rawMq.xxlarge})`,
    tablet: `@media(${rawMq.tablet})`,
  },
  shadows: {
    medium: "rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px",
  },
  spacings: {
    "sp-0": 4,
    "sp-1": 8,
    "sp-1.5": 12,
    "sp-2": 16,
    "sp-3": 24,
    "sp-4": 32,
    "sp-5": 40,
    "sp-6": 48,
    "sp-7": 56,
    "sp-8": 64,
    "sp-10": 80,
  },
  borderRadius,
  font,
} as const

export type ThemeConfig = typeof theme
