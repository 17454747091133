import { RestitutionComparables } from "@app/components/Offer/ComparableCard/types"
import { getRecaptchaRequestToken } from "@app/components/Recaptcha/GetRecaptchaRequestToken"
import { getMarketingCampaignHeaders } from "@app/lib/marketingServerToServer"
import { RestitutionComparableError } from "@app/lib/services/SnakeService/errors"
import {
  IPropertyValuationService,
  ISnakeService,
  ReconvertReturnType,
  SellerValuationResult,
} from "@app/lib/services/SnakeService/index"
import { mapBusinessLinesV2Eligibility } from "@app/lib/services/SnakeService/mapBusinessLinesV2Eligibility"
import {
  getEnergyRating,
  getFloor,
  getFloorShift,
  getHeatingSystem,
  getResidentialType,
  getStateAtDeed,
  getStateOfMaintenance,
  getViewDescription,
  isVilla,
  mapTrafficSource,
} from "@app/lib/services/SnakeService/mapPayloadToSnake"
import { getTenant } from "@app/lib/tenants/getTenant"
import { getGoogleClientId } from "@app/lib/tracking"
import { getTrafficSource } from "@app/lib/trafficSourceStorage"
import { Address } from "@app/types/address"
import { Contact } from "@app/types/contact"
import { ReportResponse } from "@app/types/fairMarketValue"
import { SellerPropertyValuation, SellerPropertyValuationId } from "@app/types/sellerPropertyValuation"
import { Answers } from "@app/types/survey"
import axios, { AxiosInstance, AxiosRequestHeaders, AxiosResponse } from "axios"
import {
  GenericInterestFormInput,
  RestReconvertResponse,
  SellerPropertyValuationRequest,
  SellerPropertyValuationResponse,
} from "./types"

export class SnakeService implements ISnakeService, IPropertyValuationService {
  private httpClient: AxiosInstance

  constructor(axiosInstance: AxiosInstance = axios) {
    this.httpClient = axiosInstance
  }

  private async buildRecaptchaHeader(action: string): Promise<AxiosRequestHeaders | {}> {
    const token = await getRecaptchaRequestToken(action)
    if (!token) {
      return {}
    }
    return {
      "casavo-recaptcha": token,
    }
  }

  async updateContact(spvId: string, data: { phone: string }) {
    const headers = await this.buildRecaptchaHeader("update_contact")

    return this.httpClient.put(
      `${process.env.NEXT_PUBLIC_SNAKE_API_BASE}/v1/seller-property-valuations/${spvId}/contact`,
      data,
      { headers }
    )
  }

  async submitSellerPropertyValuation(
    answers: Answers,
    address: Address,
    contact: Contact,
    source: string = "Sell_Flow02B"
  ): Promise<SellerValuationResult> {
    const recaptchaHeaders = await this.buildRecaptchaHeader("create_seller_property_valuation")
    const marketingCampaignHeaders = getMarketingCampaignHeaders()

    const payload = SnakeService.buildValuationRequestPayload(contact, answers, address, source)

    try {
      const { data: response } = await this.httpClient.post<SellerPropertyValuationResponse>(
        `${process.env.NEXT_PUBLIC_SNAKE_API_BASE}/v2/seller-property-valuations`,
        payload,
        { headers: { ...recaptchaHeaders, ...marketingCampaignHeaders } }
      )
      if (response.valuation === null) {
        return { status: "error", errorType: "no_price_error", error: new Error("no_price_error") }
      }

      return {
        status: "success",
        valuation: SnakeService.buildSellerValuation(response),
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          return { status: "error", errorType: "mail_or_number_already_present", error }
        }
      }
      return { status: "error", errorType: "unknown", error }
    }
  }

  private static buildSellerValuation(response: SellerPropertyValuationResponse): SellerPropertyValuation {
    const { priceRange } = response.valuation
    const max = priceRange.max ?? 0
    const min = priceRange.min ?? 0
    const mediumPrice = (min + max) / 2
    return {
      evaluation: {
        id: response.valuation.id,
        priceMax: max,
        priceMin: min,
      },
      marketingQualification: response.marketingQualification,
      askingPrices: response.askingPrices,
      fairMarketValue: response.valuation.fairMarketValue,
      askingPriceDiscountPercentage: response.valuation.askingPriceDiscountPercentage,
      businessLinesEligibility: mapBusinessLinesV2Eligibility(response.businessLines),
      price: mediumPrice,
      sellerPropertyValuationId: response.sellerPropertyValuationId,
      transactionId: response.transactionId,
    }
  }

  private static buildValuationRequestPayload(
    contact: Contact,
    answers: Omit<
      Answers,
      "INTERESTED_IN_SELLING_NOW" | "INTERESTED_IN_SELLING_IN_SIX_MONTHS" | "INTERESTED_IN_SELLING_IN_ONE_YEAR"
    >,
    address: Address,
    source: string = "Sell_Flow02B"
  ): SellerPropertyValuationRequest {
    return {
      contact: {
        email: contact.email,
        firstname: contact.name,
        lastname: contact.surname,
        marketingAllowed: contact.marketingAllowed,
        phone: contact.phone,
        sellPersonalDataAllowed: contact.dataToBeSold,
        tracking: {
          googleClientId: getGoogleClientId(),
          source: source,
        },
        valuationReasonSurvey: {
          reason: answers.valuationReason!,
          sellingProcessStatus: answers.valuationReasonSellingProcessStatus,
          reasonNotToSell: answers.valuationReasonReasonNotToSell,
          alsoInterestedInSelling: answers.valuationReasonAlsoInterestedInSelling || false,
        },
      },
      property: {
        address: {
          street: address.street,
          number: address.number,
          zipCode: address.zipCode,
          province: address.province!,
          provinceInitials: address.provinceInitials!,
          city: address.city,
          country: address.countryCode,
        },
        bathrooms: Number(answers["bathroom-count"]),
        balconies: Number(answers.balcony) || 0,
        buildingFloors: Number(answers["building-floor-count"] || answers["villa-floor-count"]),
        commercialArea: Number(answers["meters-size"]),
        constructionYear: answers.age!,
        coordinates: {
          lat: address.coordinates.latitude!,
          lon: address.coordinates.longitude!,
        },
        energyRating: getEnergyRating(answers["energetic-class"]),
        entranceFloor: isVilla(answers["residential-type"]) ? 0 : getFloor(answers["floor-count"]),
        floors: [
          {
            buildingFloor: isVilla(answers["residential-type"]) ? 0 : getFloor(answers["floor-count"]),
            entireFloor: isVilla(answers["residential-type"]),
            shift: getFloorShift(answers["floor-count"]),
          },
        ],
        gardenArea: !!answers.garden ? answers.garden : undefined,
        hasElevator: answers.elevator === "1",
        hasPool: answers.swimmingPool,
        heatingSystem: getHeatingSystem(answers["heating-system"]),
        residentialType: getResidentialType(answers["residential-type"]),
        rooms: Number(answers["room-count"]!),
        stateAtDeed: getStateAtDeed(answers.employment),
        stateOfMaintenance: getStateOfMaintenance(answers.status),
        ...(!!answers.terrace ? { terrace_areas: [Number(answers.terrace)] } : {}),
        viewDescription: answers.orientation ? getViewDescription(answers.orientation) : undefined,
      },
      adLink: answers.listingURL,
      isTest: new RegExp("viabreno", "i").test(contact.name),
      tenant: {
        id: getTenant(),
        source: mapTrafficSource(getTrafficSource()),
      },
    }
  }

  async getRealTimeValuationReport(id: SellerPropertyValuationId): Promise<ReportResponse> {
    const { data } = await this.httpClient.get<ReportResponse>(
      `${process.env.NEXT_PUBLIC_SNAKE_API_BASE}/v2/seller-property-valuations/${id}/report`
    )
    return data
  }

  async getRestitutionPageComparables(
    sellerPropertyValuationId: SellerPropertyValuationId
  ): Promise<RestitutionComparables> {
    const { data } = await this.httpClient
      .get(
        `${process.env.NEXT_PUBLIC_SNAKE_API_BASE}/v1/seller-property-valuations/${sellerPropertyValuationId}/comparables`
      )
      .catch((error: Error) => {
        if (axios.isAxiosError(error)) {
          throw new RestitutionComparableError(sellerPropertyValuationId, JSON.stringify(error.toJSON()))
        }
        throw new RestitutionComparableError(sellerPropertyValuationId, error.message)
      })

    return data
  }

  async reconvertSellerPropertyValuation(
    sellerPropertyValuationId: SellerPropertyValuationId
  ): Promise<ReconvertReturnType> {
    const recaptchaHeaders = await this.buildRecaptchaHeader("reconvert_seller_property_valuation")
    const marketingCampaignHeaders = getMarketingCampaignHeaders()

    const { data } = await this.httpClient.post<RestReconvertResponse>(
      `${process.env.NEXT_PUBLIC_SNAKE_API_BASE}/v1/seller-property-valuations/${sellerPropertyValuationId}/reconvert`,
      undefined,
      { headers: { ...recaptchaHeaders, ...marketingCampaignHeaders } }
    )

    const reconversionResult = {
      ...data,
      businessLines: mapBusinessLinesV2Eligibility(data.businessLines),
    }

    return reconversionResult
  }

  async postGenericInterestForm(genericInterestFormInput: GenericInterestFormInput): Promise<AxiosResponse> {
    const headers = await this.buildRecaptchaHeader("forward_generic_interest")
    const { data } = await this.httpClient.post(
      `${process.env.NEXT_PUBLIC_SNAKE_API_BASE}/v1/generic-interest-form`,
      genericInterestFormInput,
      { headers }
    )
    return data
  }
}
